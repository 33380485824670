import { MEMO_FOR_SETTLEMENT, SINGLE_MEMO_DATA } from '../constant/constant';

const initialValue = {
  driverInfo: null,
  vehicleInfo: null,
  driverAdvance: 0,
  bilties: [],
  trip: 'trip',
  drexp: [
    {
      exptype: null,
      desc: '',
      netamt: 0,
    },
  ],
};

export const MemoSingle = function (state = initialValue, action) {
  switch (action.type) {
    case SINGLE_MEMO_DATA: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

export const memoDataForSettlement = function (state = initialValue, action) {
  switch (action.type) {
    case MEMO_FOR_SETTLEMENT: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
