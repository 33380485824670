import dashboardRoutes from './views/dashboard/DashboardRoutes';
import NotFound from './views/sessions/NotFound';
import sessionRoutes from './views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';
import biltyRoutes from './views/Bilty/BiltyRoutes';
import mastersRoutes from './views/masters/mastersRoutes';
import profileRoutes from './views/profile/ProfileRoutes';
import memoRoutes from './views/memo/MemoRoutes';
import { AuthGuard } from './auth/AuthGuard';
import billRoutes from './views/bill/BillRoutes';
import CustRouter from './views/Cust-Enquiry/CustRouter';
import ReportRoutes from './views/reports/ReportRoutes';
import driverRoutes from './views/Driver/DriverRouter';
import accRoutes from './views/account/AccRoutes';

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...dashboardRoutes,
      ...biltyRoutes,
      ...memoRoutes,
      ...mastersRoutes,
      ...profileRoutes,
      ...billRoutes,
      ...CustRouter,
      ...ReportRoutes,
      ...driverRoutes,
      ...accRoutes,
    ],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="admindashboard" /> },
  { path: '*', element: <NotFound /> },
];

export default routes;
